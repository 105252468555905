<template>
    <div id="success-page">
      <div class="success-content">
        <div class="checkmark"></div>
        <h1>Payment Successful!</h1>
        <p>thank you for your purchase. we hope you love your new miss hadid piece.</p>
        <!-- Adjust link as desired -->
        <router-link to="/">
          <button class="close-success-btn">continue</button>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SuccessPage"
  };
  </script>
  
  <style scoped>
  #success-page {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c5c2c2;
    font-family: 'Azeret Mono', monospace;
    position: relative;
  }
  
  .success-content {
    text-align: center;
    animation: fadeIn 0.5s ease forwards;
  }
  
  /* Animated checkmark */
  .checkmark {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #c5c2c2;
    position: relative;
    margin: 0 auto 20px;
    animation: scaleIn 0.6s ease forwards;
  }
  .checkmark::after {
    content: "";
    position: absolute;
    left: 22px;
    top: 10px;
    width: 15px;
    height: 30px;
    border: solid black;
    border-width: 0 6px 6px 0;
    transform: rotate(45deg);
    animation: drawCheck 0.6s ease forwards;
  }
  
  /* Button */
  .close-success-btn {
    background: #c5c2c2;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Azeret Mono', monospace;
    text-transform: uppercase;
    border-radius: 8px;
    margin-top: 20px;
  }
  .close-success-btn:hover {
    background-color: #9b9999;
  }
  
  /* Keyframes */
  @keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes scaleIn {
    0%   { transform: scale(0.2); opacity: 0; }
    80%  { transform: scale(1.2); }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes drawCheck {
    0%   { width: 0; height: 0; }
    100% { width: 15px; height: 30px; }
  }
  </style>
  
<template>
    <div id="refund-policy">
      <div class="container">
        <h1>Refund Policy</h1>
        <p>
          At Miss Hadid, we believe in delivering high-quality, fashion-forward pieces that reflect our commitment to sustainability, innovation, and design excellence. If you are not completely satisfied with your purchase, our refund policy is designed to ensure a smooth and fair process.
        </p>
        <h2>Eligibility for Refunds</h2>
        <p>
          Refund requests are accepted within 30 days of the purchase date. Items must be unused, in the same condition that you received them, and in the original packaging.
        </p>
        <h2>Non-Refundable Items</h2>
        <p>
          Certain types of items are non-refundable, such as personalized or custom orders. Sale items are also exempt from refunds unless they are defective.
        </p>
        <h2>The Refund Process</h2>
        <p>
          To initiate a refund, please contact our customer service team at <strong>support@misshadid.com</strong> with your order details. Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed and a credit will automatically be applied to your original method of payment within a certain number of days.
        </p>
        <h2>Late or Missing Refunds</h2>
        <p>
          If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company; it may take some time before your refund is officially posted. If you’ve done all of this and still have not received your refund, please contact us at <strong>support@misshadid.com</strong>.
        </p>
        <h2>Exchanges</h2>
        <p>
          We only replace items if they are defective or damaged. If you need to exchange it for the same item, please contact us at <strong>support@misshadid.com</strong>.
        </p>
        <h2>Shipping</h2>
        <p>
          You will be responsible for paying your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about our Refund Policy, please contact us at <strong>support@misshadid.com</strong>.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RefundPolicy"
  };
  </script>
  
  <style scoped>
  #refund-policy {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    color: #333;
    font-family: 'Azeret Mono', monospace;
  }
  h1 {
    font-size: 2.5em;
    color: #C5C2C2;
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #C5C2C2;
  }
  p {
    line-height: 1.6;
    margin: 10px 0;
  }
  strong {
    color: #000;
  }
  </style>
  
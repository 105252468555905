<template>
    <div id="product-page">
      <!-- Loading Overlay -->
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-content">
          <div class="spinner"></div>
          <p>miss-hadid design</p>
        </div>
      </div>
  
      <!-- Carousel Section -->
      <div class="carousel">
        <div
          v-for="(slide, index) in currentProduct.slides"
          :key="index"
          :class="['slide', { active: currentSlide === index }]"
        >
          <!-- Image Slide -->
          <template v-if="slide.type === 'image'">
            <img
              :src="slide.src"
              alt="Product image"
              class="media-frame"
              @load="handleAssetLoaded"
            />
          </template>
          <!-- Video Slide -->
          <template v-else-if="slide.type === 'video'">
            <div class="video-container">
              <video
                ref="productVideo"
                :src="slide.src"
                autoplay
                muted
                loop
                playsinline
                class="media-frame"
                @loadeddata="handleAssetLoaded"
              ></video>
              <button class="mute-btn" @click="toggleMute">
                <span v-if="videoMuted">
                  <!-- Mute Icon -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#c5c2c2"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M16.5 12l3.5 3.5-1.41 1.41L15.09 13.41 11.5 17H8v-4H3v-2h5V7h3.5l4.59 4.59z"
                    />
                  </svg>
                </span>
                <span v-else>
                  <!-- Unmute Icon -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#c5c2c2"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M3 10v4h4l5 5V5l-5 5H3z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </span>
              </button>
            </div>
          </template>
        </div>
  
        <!-- Carousel Controls -->
        <div class="carousel-controls">
          <button @click="prevSlide" class="carousel-btn">‹</button>
          <button @click="nextSlide" class="carousel-btn">›</button>
        </div>
      </div>
  
      <!-- Product Details -->
      <div class="product-details">
        <div class="product-header">
          <h2 class="product-name">{{ currentProduct.name }}</h2>
          <p class="product-price">{{ currentProduct.price }}</p>
        </div>
  
        <!-- Collapsible Description -->
        <p class="product-description">
          <span v-if="showFullDescription">
            {{ currentProduct.fullDescription }}
          </span>
          <span v-else>
            {{ currentProduct.truncatedDescription }}...
          </span>
          <button class="read-more-btn" @click="toggleDescription">
            {{ showFullDescription ? 'Read Less' : 'Read More' }}
          </button>
        </p>
  
        <!-- Buy Button -->
        <button class="buy-button" @click="buyNow">Buy Now</button>
  
        <!-- Size Options -->
        <div class="size-options">
          <div
            class="size-circle"
            v-for="(size, index) in currentProduct.sizes"
            :key="index"
          >
            {{ size }}
          </div>
          <p class="size-guide" @click="showSizeGuide = true">size guide</p>
        </div>
      </div>
  
      <!-- Size Guide Pop-up -->
      <div v-if="showSizeGuide" class="size-guide-popup">
        <div class="popup-content">
          <h3>Size Guide</h3>
          <table>
            <tr>
              <th>Size</th>
              <th>Bust (in)</th>
              <th>Waist (in)</th>
              <th>Hips (in)</th>
            </tr>
            <tr>
              <td>S</td>
              <td>34-35</td>
              <td>26-27</td>
              <td>36-37</td>
            </tr>
            <tr>
              <td>M</td>
              <td>36-37</td>
              <td>28-29</td>
              <td>38-39</td>
            </tr>
            <tr>
              <td>L</td>
              <td>38-40</td>
              <td>30-32</td>
              <td>40-42</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>41-43</td>
              <td>33-35</td>
              <td>43-45</td>
            </tr>
          </table>
          <button class="close-popup" @click="showSizeGuide = false">
            Close
          </button>
        </div>
      </div>
  
      <!-- Next/Back Buttons for product navigation -->
      <div class="product-navigation">
        <button class="nav-btn" @click="prevProduct">← Back</button>
        <button class="nav-btn" @click="nextProduct">Next →</button>
      </div>
    </div>
</template>
  
<script>
import { inject } from "@vercel/analytics";

export default {
  name: "ProductPage",
  data() {
    return {
      // We have multiple products in an array, each with a unique buyLink
      products: [
      {
          name: "zendaya",
          price: "$270.00",
          truncatedDescription: "zendaya : aesthetic function",
          fullDescription:
            'zendaya by miss-hadid is crafted with great artistry and precision. flowing lines at the back connect at a single point, creating a beautiful visual illusion. the upper waist, stomach and upper body compression gently creates a perfectly defined natural body curve.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/zen.png" },
            { type: "image", src: "/images/zen02.png" },
            { type: "image", src: "/images/zen03.png" }
          ],
          buyLink: "https://paystack.com/pay/vur618xwbq"
        },
        {
          name: "victoria",
          price: "$599.00",
          truncatedDescription: "victoria : understated allure",
          fullDescription:
            'where soft elegance meets bold confidence. “victoria” by miss-hadid honors the modern woman’s quiet strength, weaving delicate lace into a sculpted silhouette. it’s a graceful testament to femininity, power, and understated allure.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/vic01.png" },
            { type: "image", src: "/images/vic02.png" },
            { type: "image", src: "/images/vic03.png" }
          ],
          buyLink: "https://paystack.com/pay/aliolaslk3"
        },
        {
          name: "mosaic echoes",
          price: "$180.00",
          truncatedDescription: "mosaic echoes: art in motion",
          fullDescription:
            '"mosaic echoes" by miss-hadid is where modern structure meets timeless artistry. inspired by intricate patterns and bold contrasts, this piece balances fluidity with precision, embodying the brand’s ethos of "less is more" while making an undeniable statement. with sharp tailoring and rich textures, it redefines contemporary elegance—proof that simplicity and complexity can exist in perfect harmony.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/003.png" },
            { type: "video", src: "/videos/002a.mov" },
            { type: "image", src: "/images/001.png" }
          ],
          buyLink: "https://paystack.com/pay/2ywjh8-b6i"
        },
        {
          name: "opal hush",
          price: "$195.00",
          truncatedDescription: "opal hush : fluidity",
          fullDescription:
            'soft lines, quiet confidence. "opal hush" by miss-hadid merges modern structure with understated allure. the crisp white hue and gentle fit accentuate your silhouette, echoing our "less is more" philosophy. step into "opal hush" and let subtlety make a powerful statement.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/opal01.png" },
            { type: "image", src: "/images/opal02.png" },
            { type: "image", src: "/images/opal03.png" }
          ],
          buyLink: "https://paystack.com/pay/syf-jejgb0"
        },
        {
          name: "noir reverie",
          price: "$250.00",
          truncatedDescription: "noir reverie: timeless sophistication",
          fullDescription:
            'noir reverie" by miss-hadid embodies refined elegance. this impeccably tailored suit exudes modern sophistication with sleek lines and a "less is more" approach. the deep black fabric, combined with a subtle sheen on the lapels, creates a look that is both commanding and graceful. perfect for the woman who embraces her strength and poise, this suit transforms any occasion into a celebration of style and confidence.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/noir01.png" },
            { type: "image", src: "/images/noir02.png" },
            { type: "image", src: "/images/noir03.png" }
          ],
          buyLink: "https://paystack.com/pay/--77duvve4"
        },
        {
          name: "angel in distress",
          price: "$599.00",
          truncatedDescription: "vivid serenade: color in motion",
          fullDescription:
            'a delicate blend of elegance and allure, "angel in distress" by miss-hadid fuses intricate lace with a sleek black silhouette. the subtle transparency and refined structure capture both vulnerability and quiet strength, echoing the brand’s modernist inspiration. slip into "angel in distress" and let its understated drama speak volumes of confidence and grace.',
          sizes: ["S", "M", "L", "XL"],
          slides: [
            { type: "image", src: "/images/angel.png" },
            { type: "image", src: "/images/angel02.png" },
            { type: "image", src: "/images/angel03.png" }
          ],
          buyLink: "https://paystack.com/pay/opkvvl6ljx"
        }
      ],
      currentProductIndex: 0, // Which product are we currently viewing?
      currentSlide: 0,       // Slideshow for the current product

      videoMuted: true,
      showFullDescription: false,
      showSizeGuide: false,
      isLoading: true
    };
  },
  computed: {
    currentProduct() {
      return this.products[this.currentProductIndex];
    }
  },
  methods: {
    // Carousel next/prev for images/videos
    nextSlide() {
      const totalSlides = this.currentProduct.slides.length;
      this.currentSlide = (this.currentSlide + 1) % totalSlides;
    },
    prevSlide() {
      const totalSlides = this.currentProduct.slides.length;
      this.currentSlide =
        (this.currentSlide - 1 + totalSlides) % totalSlides;
    },

    // Mute/unmute video
    toggleMute() {
      this.videoMuted = !this.videoMuted;
      const video = this.$refs.productVideo;
      if (video) {
        video.muted = this.videoMuted;
      }
    },

    // Toggle full description
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },

    // Use the product-specific buyLink
    buyNow() {
      window.location.href = this.currentProduct.buyLink;
    },

    // Called once the image/video is loaded
    handleAssetLoaded() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

    // Switch to previous product
    prevProduct() {
      // Reset slides & loading
      this.currentSlide = 0;
      this.isLoading = true;
      this.currentProductIndex =
        (this.currentProductIndex - 1 + this.products.length) %
        this.products.length;
    },

    // Switch to next product
    nextProduct() {
      // Reset slides & loading
      this.currentSlide = 0;
      this.isLoading = true;
      this.currentProductIndex =
        (this.currentProductIndex + 1) % this.products.length;
    }
  },
  mounted() {
    // Inject Vercel Analytics when the component mounts
    inject();
  }
};
</script>

<style scoped>
/* Global reset for this component (if not set globally) */
html, body, #app {
  margin: 0;
  padding: 0;
  background-color: black;
  color: #c5c2c2;
  font-family: 'Azeret Mono', monospace;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Container for the product page */
#product-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* For pop-up and loading overlay positioning */
}

/* Loading Overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}
.loading-content {
  text-align: center;
  color: #c5c2c2;
}
.spinner {
  border: 4px solid rgba(197, 194, 194, 0.3);
  border-top: 4px solid #c5c2c2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Carousel */
.carousel {
  position: relative;
  width: 80%;
  max-width: 500px;
  margin-bottom: 20px;
}
.slide {
  display: none;
}
.slide.active {
  display: block;
}

/* Media frame using aspect-ratio for consistency */
.media-frame {
  width: 100%;
  aspect-ratio: 4 / 5;
  object-fit: cover;
  border-radius: 15px;
}

/* Video container & mute button */
.video-container {
  position: relative;
}
.mute-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(197, 194, 194, 0.8);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  font-size: 0.9em;
}

/* Carousel controls */
.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}
.carousel-btn {
  background: none;
  border: none;
  font-size: 2em;
  color: #c5c2c2;
  cursor: pointer;
}

/* Product details */
.product-details {
  width: 80%;
  max-width: 500px;
  text-align: center;
  margin-top: 10px;
}
.product-header {
  display: flex;
  justify-content: space-between; /* name on left, price on right */
  align-items: baseline;          /* align like first image */
  margin-bottom: 10px;
}
.product-name {
  margin: 0;
  font-size: 1.5em;
  text-align: left;
}
.product-price {
  margin: 0;
  font-size: 1.2em;
}

/* Collapsible description */
.product-description {
  margin: 20px 0;
  text-align: left;
  line-height: 1.5;
}
.read-more-btn {
  background: none;
  border: none;
  color: #c5c2c2;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
  font-size: 0.9em;
}

/* Buy button */
.buy-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(45deg, #ff6a00, #ee0979);
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Size options */
.size-options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #c5c2c2;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 0.9em;
}
.size-guide {
  margin: 0;
  font-size: 0.8em;
  text-decoration: underline;
  cursor: pointer;
}

/* Size Guide Pop-up */
.size-guide-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  z-index: 100;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: black;
}
.popup-content h3 {
  margin-bottom: 10px;
}
.popup-content table {
  width: 100%;
  border-collapse: collapse;
}
.popup-content th,
.popup-content td {
  border: 1px solid black;
  padding: 8px;
}
.close-popup {
  margin-top: 15px;
  background: black;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

/* Next/Back Buttons for product navigation */
.product-navigation {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 500px;
  margin: 20px auto 0;
}
.nav-btn {
  background-color: #c5c2c2;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Azeret Mono', monospace;
  text-transform: uppercase;
  border-radius: 8px;
}
.nav-btn:hover {
  background-color: #9b9999;
}

/* Mobile responsive adjustments */
@media (max-width: 600px) {
  #product-page {
    padding: 10px;
  }
  .carousel {
    width: 100%;
    max-width: 400px;
  }
  .product-details {
    width: 100%;
    max-width: 400px;
  }
  .product-navigation {
    width: 100%;
    max-width: 400px;
  }
  .nav-btn {
    padding: 8px 12px;
    font-size: 0.8em;
  }
}
</style>

// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import RefundPolicy from '@/components/RefundPolicy.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import ProductPage from '@/components/ProductPage.vue'
import SuccessPage from '@/components/SuccessPage.vue'


const routes = [
  { path: '/', component: HomePage },
  { path: '/refund-policy', component: RefundPolicy },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/product', component: ProductPage },
  { path: '/success', component: SuccessPage }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

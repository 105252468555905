<template>
    <div id="privacy-policy">
      <div class="container">
        <h1>Privacy Policy</h1>
        <p>
          At Miss Hadid, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you visit our website and use our services.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, mailing address, phone number, and payment information when you make a purchase or register on our website. In addition, we collect non-personal information such as browser type, operating system, and usage data via cookies and similar technologies.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          Your information is used to process orders, provide customer support, send promotional emails (if you opt in), and improve our services. We also use your data for analytics purposes to better understand user behavior and preferences.
        </p>
        <h2>Information Sharing and Disclosure</h2>
        <p>
          We do not sell or rent your personal information to third parties. We may share your data with trusted service providers who help us operate our website and conduct our business, all under strict confidentiality agreements. We may also disclose your information when required by law or to protect our rights.
        </p>
        <h2>Data Security</h2>
        <p>
          We implement a variety of security measures to safeguard your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so while we strive to protect your data, we cannot guarantee its absolute security.
        </p>
        <h2>Cookies and Tracking Technologies</h2>
        <p>
          Our website uses cookies to enhance your browsing experience. You can set your browser to refuse all cookies or to notify you when a cookie is being sent. Please note that if you disable cookies, some parts of our site may not function properly.
        </p>
        <h2>Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal information at any time. You may also opt out of receiving promotional communications from us by following the unsubscribe instructions in our emails.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. Any changes will be posted on this page, and the updated policy will be effective immediately upon posting.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or our data practices, please contact us at <strong>support@misshadid.com</strong>.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy"
  };
  </script>
  
  <style scoped>
  #privacy-policy {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    color: #333;
    font-family: 'Azeret Mono', monospace;
  }
  h1 {
    font-size: 2.5em;
    color: #C5C2C2;
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #C5C2C2;
  }
  p {
    line-height: 1.6;
    margin: 10px 0;
  }
  strong {
    color: #000;
  }
  </style>
  
<template>
  <div id="app">
    <!-- The router will inject the matching component here -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* GLOBAL RESET */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  color: #C5C2C2;
  font-family: 'Azeret Mono', monospace;
  box-sizing: border-box;
}

/* The root Vue app container */
#app {
  min-height: 100vh; /* ensures it fills at least the viewport height */
  display: flex;
  flex-direction: column;
}
</style>
